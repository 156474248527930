import store from 'store';

function removeHowToPay() {
  $('#how-to-pay-popup').remove();
  $(document).off('hidden.bs.modal', removeHowToPay);
}

function initCancelButton() {
  $('.js-payment-canceled').on('click', function() {
    $(document).on('hidden.bs.modal', removeHowToPay);

    store.set('perma', false);
    pomagamy.close_popup();
    return false;
  });
}

function initGoToPaymentButton($popups) {
  // Handles redirect to a payment provider.
  // Opening provider's page in a new window triggers changes in popup,
  // resulting in different content/buttons.
  var $go_to_payment = $popups.find('#js-go-to-payment');

  function updatePopupContent() {
    $popups.find('.modal.show .provider-info').toggleClass('hidden');
    $popups
      .find('.modal.show .popup-footer .provider-info')
      .toggleClass('d-flex button-group w-100 justify-content-between');
    $popups.find('.modal.show #js-cancel-pre-payment').hide();
    $go_to_payment.hide();
  }

  $go_to_payment.on('click', function() {
    const in_new_window = $go_to_payment.attr('target') === '_blank';
    if (in_new_window) {
      store.set('perma', true);
      updatePopupContent();
      window.open($go_to_payment.attr('href'), '_blank');
      return false;
    } else {
      store.set('perma', false);
    }
  });
}

function initZenForm($zen_cc_form) {
  const $zen_form_error = $zen_cc_form.find('.form-err');

  // remove form error (custom) on submit click
  $zen_cc_form
    .closest('.modal')
    .find('.target-submit')
    .on('click', function(e) {
      $zen_form_error.removeClass('visible');
      $zen_form_error.find('.message').text('');
    });

  $zen_cc_form.submit(function(event) {
    // This function is called only on the first submit.
    // It's ok tho, main form data needs to be passed here only once.
    event.preventDefault(); // this will prevent the default submit
    // Before-submit code goes here.
    // This way code can be executed before global form.ajax onSubmit binding
    // Don't use async code here!
    const main_form_data = $('#payment-form').serializeArray();
    $.each(main_form_data, function() {
      $('<input />')
        .attr('type', 'hidden')
        .attr('name', this.name)
        .attr('value', this.value)
        .appendTo($zen_cc_form);
    });
    $(this)
      .unbind('submit')
      .submit();
    // Continue the submit unbind preventDefault
  });
}

export function HowToPayOnLoad($popups) {
  initCancelButton();

  // Temporarily disable modal.hide feature.
  // TODO - fix perma for stripe payments
  store.set('perma', true);

  initGoToPaymentButton($popups);

  const $zen_cc_form = $popups.find('#js-zen-cc-form');
  if ($zen_cc_form.length) {
    initZenForm($zen_cc_form);
  }
}
