const defaults = {
  maxLength: 160,
  label: gettext('cmn-load-more'),
};

export class ReadMore {
  constructor($context, options) {
    this.settings = Object.assign({}, defaults, options);

    this.$context = $context;

    this.text = this.$context.text();
    this.isExpanded = true;

    // Init only when needed
    if (this.text.length > this.settings.maxLength) {
      this.init();
    }
  }

  init() {
    this.hide();
  }

  hide() {
    if (this.isExpanded) {
      const truncated = ReadMore.truncate(
        this.text,
        this.settings.maxLength,
        true,
      );
      const $button = $(`<a href="#">${this.settings.label}</a>`);

      $button.on('click', e => {
        e.preventDefault();
        this.show();
      });

      this.$context.html(truncated);
      this.$context.append($button);
      this.isExpanded = false;
    }
  }

  show() {
    if (!this.isExpanded) {
      this.$context.html(this.text);
      this.isExpanded = true;
    }
  }

  static truncate(text, maxLength, useWordBoundary) {
    if (text.length <= maxLength) {
      return text;
    }
    let rawTruncate = text.substr(0, maxLength - 1);
    let subString = useWordBoundary
      ? rawTruncate.substr(0, rawTruncate.lastIndexOf(' '))
      : rawTruncate;

    let words = subString.split(' ');
    words.push(
      words.pop().replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ''),
    );

    subString = words.join(' ');

    return subString + '&hellip; ';
  }
}

export function initReadMore($context, options) {
  const retval = [];
  $context.each((index, element) => {
    retval.push(new ReadMore($(element), options));
  });
  return retval;
}
